import React, { useState, useEffect, useRef } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";

const libraries = ["places"];

const AddressAutocomplete = ({
  label,
  name,
  errors,
  setValue,
  disabled = false,
  initialAddress = "",
  readOnly = false,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const [typedAddress, setTypedAddress] = useState(initialAddress);
  const autocompleteRef = useRef(null);

  // On first mount (and if readOnly changes to true),
  // sync typedAddress with the parent's initialAddress.
  useEffect(() => {
    if (readOnly) {
      setTypedAddress(initialAddress);
    }
    // If readOnly is false, we leave typedAddress as is,
    // so the user can type freely without being overwritten.
  }, [readOnly, initialAddress]);

  // Whenever typedAddress changes, update the form's "Address" field.
  // If typedAddress is empty, clear out lat/lng so we don't keep stale coords.
  useEffect(() => {
    setValue(`${name}.Address`, typedAddress || "", { shouldValidate: true });
    if (!typedAddress) {
      setValue(`${name}.Latitude`, "", { shouldValidate: false });
      setValue(`${name}.Longitude`, "", { shouldValidate: false });
    }
  }, [typedAddress, name, setValue]);

  const onLoad = (autocompleteObj) => {
    autocompleteRef.current = autocompleteObj;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const newAddress = place?.formatted_address || "";
      const latitude = place.geometry?.location?.lat?.() ?? "";
      const longitude = place.geometry?.location?.lng?.() ?? "";

      setTypedAddress(newAddress);
      setValue(`${name}.Latitude`, latitude, { shouldValidate: true });
      setValue(`${name}.Longitude`, longitude, { shouldValidate: true });
    }
  };

  if (loadError) return <div>Error loading Google Maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      {label && <label className="text-sm mb-2 block">{label}</label>}
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
          value={typedAddress}
          onChange={(e) => {
            if (!readOnly) {
              setTypedAddress(e.target.value);
            }
          }}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={
            disabled
              ? ""
              : "Address..."
          }
        />
      </Autocomplete>
      {errors?.Address && (
        <p className="text-red-500 text-xs mt-1">{errors.Address.message}</p>
      )}
    </div>
  );
};

export default AddressAutocomplete;
