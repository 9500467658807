import { Grid } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Constants } from "../../Constants";
import { FetchAllBlogPosts } from "../../Thunk/blogThunks";
import { useForm } from "react-hook-form";
export const Blog = () => {
  const { blogList } = useSelector((state) => state.blog);
  const {register,watch} = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchAllBlogPosts());
  }, []);

  const filterCriteria = watch('blogFilter');
  const filteredBlogs = useMemo(() => {
    let blog = blogList;
    if (filterCriteria !== 'posted by') {
      blog = blog.filter((itx) => itx.blogPostedBy[0].postedBy === filterCriteria);
    }
    return blog;

}, [blogList, filterCriteria]);


  return (
    <>
      <section className="border-t-2 bg-[#F6F6F6]">
        <div className="container mx-auto px-4 py-8">
          <div className="md:flex gap-3 items-center justify-between">
            <div className="mb-5 md:mb-0">
              <h1 className="text-3xl text-center font-bold">Blog</h1>
            </div>
            <div className="flex gap-3 items-center">
              <div>
                <label className="text-sm m-w-">Filter:</label>
              </div>
              <select {...register('blogFilter')}  className="border border-black w-full py-2  px-4 rounded-lg text-sm ">
                <option>posted by</option>
                {blogList?.map((itx) => (
                   <option>{itx.blogPostedBy[0].postedBy}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto px-4 py-10">
          <Grid container spacing={4}>
            {filteredBlogs.length > 0 ? (
              filteredBlogs?.map((itx) => (
                <Grid item lg={3} md={6} xs={12} key={itx._id}>
                  <Link to={`/blog-details/${itx._id}`} state={itx}>
                    <div>
                      <img
                        src={`${Constants.getImg}${itx.blogImage}`}
                        className="w-full h-44 object-cover rounded-[20px]"
                      />
                    </div>
                    <div className="p-4 bg-gray-100 rounded-[10px] w-11/12 m-auto mt-[-25px] relative hover:shadow-xl transition-all">
                      <div className="flex gap-3 items-center mb-3">
                        <div className="px-2 py-2 bg-primary text-xs text-white rounded-lg">
                          {moment(itx.blogPostDate).format("DD MMM, YYYY")}
                        </div>
                        <div className="text-xs">
                          <i class="fa-solid fa-user text-primary me-2"></i>
                          By{" "}
                          {itx.blogPostedBy[0]?.postedBy?.substring(0, 10) +
                            "......"}
                        </div>
                      </div>
                      <h2 className="text-md font-bold mb-3">
                        {itx?.blogTitle?.substring(0,46) + "....."}
                      </h2>
                      <div
                        className="text-sm text-gray-600 mb-3"
                        dangerouslySetInnerHTML={{
                          __html: itx?.blogContent?.substring(0, 62) + "..",
                        }}
                      ></div>
                      <p className="text-sm font-semibold text-primary">
                        <i class="fa-solid fa-right-long"></i> Read More
                      </p>
                    </div>
                  </Link>
                </Grid>
              ))
            ) : (
              <p className="font-bold text-lg w-full text-center">
                No Blogs Posted !
              </p>
            )}
          </Grid>
        </div>
      </section>
    </>
  );
};
