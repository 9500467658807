import { useEffect } from "react";
import { DeleteBlogPost, FetchBlogPostsByUser } from "../../Thunk/blogThunks";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Constants } from "../../Constants";
import BannerDashboard from "../Dashboard/BannerDashboard";
import ToggleNavs from "../ToggleNavs/toggleNavs";
import toast from "react-hot-toast";
export const BlogList = () => {
  const { user } = useSelector((state) => state.auth);
  const { doctorBlogList,msg } = useSelector((state) => state.blog);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchBlogPostsByUser({ Id: user.Id }));
  }, [msg]);

  const deleteBlogPost = (blogId) => {
     dispatch(DeleteBlogPost({Id:blogId}))
  }
  useEffect(() => {
      if(msg === 'Blog Deleted Successfully!') {
        toast.success(msg)
      }
  },[msg])
  return (
    <>
      {" "}
      <BannerDashboard pageTitle="My Blog List" />
      <section className="py-8 bg-slate-50">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <ToggleNavs />
            </Grid>

            <Grid item xs={12} md={8} lg={9}>
              <h2 className="text-2xl font-semibold  pb-3 mb-6 border-b">
                Blog Lists
              </h2>
              <div className="mt-3 text-right">
                <button
                  className=" bg-primary rounded-lg text-white font-semibold py-3 px-8 hover:bg-black transition-all delay-200 text-sm"
                  onClick={() => navigation("/add-blog")}
                >
                  Post Blog
                </button>
              </div>
              <div className="mt-4">
                {doctorBlogList?.length > 0 ? (
                  <Grid container columns={12} spacing={2}>
                    {doctorBlogList.map((itx, index) => (
                      <Grid key={index} item lg={4} md={6} sm={12}>
                        
                          <div className="relative">
                            <img
                              src={`${Constants.getImg}${itx.blogImage}`}
                              className="aspect-[4/3] object-cover rounded-[20px]"
                            />
                            <div className="flex justify-end absolute top-3 right-3 ">
                              <button className="blogButton" onClick={() => navigation('/add-blog',{state:itx})}>
                                <i class="fa-solid fa-pen-to-square text-blue-500"></i>
                              </button>
                              <button className="blogButton" onClick={() => deleteBlogPost(itx._id)}>
                                <i class="fa-solid fa-trash text-red-500"></i>
                              </button>
                            </div>
                          </div>
                          <div className="p-4 bg-gray-100 rounded-[10px] w-11/12 m-auto mt-[-25px] relative hover:shadow-xl transition-all">
                            <div className="flex gap-3 items-center mb-3">
                              <div className="px-2 py-2 bg-primary text-xs text-white rounded-lg">
                                {moment(itx.blogPostDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </div>
                              <div className="text-xs">
                                <i class="fa-solid fa-user text-primary me-2"></i>
                                By{" "}
                                {itx.blogPostedBy[0]?.postedBy?.substring(0, 10) +
                                  "......"}
                              </div>
                            </div>

                            <h2 className="text-md font-bold mb-3">
                              {itx.blogTitle}
                            </h2>
                            <div
                              className="text-sm text-gray-600 mb-3"
                              dangerouslySetInnerHTML={{
                                __html: itx?.blogContent?.substring(0, 62) + "..",
                              }}
                            ></div>
                            <Link to={`/blog-details/${itx._id}`} >
                            <p className="text-sm font-semibold text-primary">
                              <i class="fa-solid fa-right-long"></i> Read More
                            </p>
                            </Link>
                          </div>
                        
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <div className="flex w-full h-full justify-center mt-5">
                    <p className="font-semibold">No Blogs Posted !</p>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};
