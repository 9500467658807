export const ConvertToFormData = async (data) => {
  const formData = new FormData();
  const ProfessionalInformation = {};
  const LicensingInformation = {};

  if (typeof data == "object" && data !== null) {
    for (const key in data) {
      // 1) Move 'Specializations', 'Medical_School_Attended', etc. to ProfessionalInformation
      if (
        key === "Medical_School_Attended" ||
        key === "Degree" ||
        key === "Specializations" ||
        key === "work_experience"
      ) {
        ProfessionalInformation[key] = data[key];
      }

      // 2) Append other direct fields (everything except the keys below) to formData
      if (
        key !== "Medical_License_Number" &&
        key !== "Issuing_State" &&
        key !== "Issuing_Country" &&
        key !== "dt_of_issue" &&
        key !== "Medical_School_Attended" &&
        key !== "Degree" &&
        key !== "Specializations" &&
        key !== "work_experience" &&
        key !== "languages_can_speak" &&
        key !== "clinics" &&
        key !== "Degree_Certificate"
      ) {
        formData.append(key, data[key]);
      }

      // 3) Handle languages_can_speak as an array
      if (key === "languages_can_speak") {
        // Remove: formData.append(key, JSON.stringify(data[key]));
        // Add the bracket-notation approach:
        const languages = data[key]; // e.g. ["Nepali", "French", "English"]
        if (Array.isArray(languages)) {
          languages.forEach((lang) => {
            formData.append("languages_can_speak[]", lang);
          });
        }
      }

      // 4) Clinics as JSON (if that’s what your backend expects)
      if (key === "clinics") {
        formData.append(
          "Clinics",
          JSON.stringify(
            data[key].map((itx) => ({
              clinic_name: itx.clinic_name,
              clinic_address: itx.clinic_address,
            }))
          )
        );
      }

      // 5) Handle Degree_Certificate as File
      if (key === "Degree_Certificate") {
        formData.append(key, data[key][0]);
      }

      // 6) LicensingInformation
      if (
        key === "Medical_License_Number" ||
        key === "Issuing_State" ||
        key === "Issuing_Country" ||
        key === "dt_of_issue"
      ) {
        LicensingInformation[key] = data[key];
      }
    }

    // 7) Finally, append JSONified objects for Professional & Licensing
    formData.append(
      "ProfessionalInformation",
      JSON.stringify(ProfessionalInformation)
    );
    formData.append(
      "LicensingInformation",
      JSON.stringify(LicensingInformation)
    );
  }
  return formData;
};
