import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import { addPatientProfile } from "../../Thunk/patientThunks";

import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ChildrenSection from "../PersonalSections/ChildrenSection";
import AccordionHeader from "../PersonalSections/AccordianSummaryHeader/AccordionHeader";
import ParentsSection from "../PersonalSections/ParentsSection";
import AddressAutocomplete from "./AddressAutocomplete";

const PersonalForm = ({
  disabledFields,
  isEditable,
  setIsPersonalEditing,
  setPersonalFieldDisable,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { msg, error, patientData } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Name_Surname: user.Name || "",
      mail: user.email || "",
      DOB: patientData?.DOB || "",
      // Domicile
      "Domicile.Address": patientData?.Domicile?.Address || "",
      "Domicile.Latitude": patientData?.Domicile?.Latitude || "",
      "Domicile.Longitude": patientData?.Domicile?.Longitude || "",
      // Residence
      "Residence.Address": patientData?.Residence?.Address || "",
      "Residence.Latitude": patientData?.Residence?.Latitude || "",
      "Residence.Longitude": patientData?.Residence?.Longitude || "",

      Mobilephone: patientData?.Mobilephone || "",
      Qualification: patientData?.Qualification || "",
      Profession: patientData?.Profession || "",
      Maritalstatus: patientData?.Maritalstatus || "",
      Place_of_birth: patientData?.Place_of_birth || "",
      TaxIDcode: patientData?.TaxIDcode || "",

      // Family
      has_children: patientData?.Children?.has_children || "no",
      children_gender: patientData?.Children?.children_gender || "",
      type_of_delivery: patientData?.Children?.type_of_delivery || "",
      pregnancy_complications:
        patientData?.Children?.pregnancy_complications || "",
      are_you_pregnant: patientData?.Children?.are_you_pregnant || "no",
      week_of_gestation: patientData?.Children?.week_of_gestation || "",

      have_brothers_sisters:
        patientData?.Brother_sisters?.have_brothers_sisters || "no",
      how_many_brothers_sisters:
        patientData?.Brother_sisters?.how_many_brothers_sisters || "",

      isParentsAlive: patientData?.Parents_alive?.isParentsAlive || "no",
      who_is_alive: patientData?.Parents_alive?.who_is_alive || "",

      ASL_assistance: patientData?.ASL_assistance || "",
      General_Practitioner_Name: patientData?.General_Practitioner_Name || "",

      // "Same as Domicile" checkbox
      sameAsDomicile: false,
    },
  });

  const has_children = watch("has_children");
  const children_gender = watch("children_gender");
  const isParentsAlive = watch("isParentsAlive");
  const have_brothers_sisters = watch("have_brothers_sisters");
  const sameAsDomicile = watch("sameAsDomicile");

  /**
   * ONE-TIME COPY:
   * If the user checks "Same as Domicile?" for the first time,
   * we copy what's currently in Domicile over to Residence. 
   * We do NOT watch "Domicile.Address" on every keystroke, to avoid
   * repeated updates or infinite loops. 
   *
   * If you want dynamic copying whenever Domicile changes, 
   * you can add [watch("Domicile.Address")] to the dependency array.
   */
  useEffect(() => {
    if (sameAsDomicile) {
      // Copy from Domicile to Residence once:
      const domicileAddress = watch("Domicile.Address");
      const domicileLat = watch("Domicile.Latitude");
      const domicileLng = watch("Domicile.Longitude");

      setValue("Residence.Address", domicileAddress);
      setValue("Residence.Latitude", domicileLat);
      setValue("Residence.Longitude", domicileLng);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameAsDomicile]);

  const handlePersonalInfo = (data) => {
    const formattedData = {
      PatientId: user.PatientId,
      isUpdated: true,
      Name_Surname: data.Name_Surname,
      mail: data.mail,
      DOB: data.DOB,
      Place_of_birth: data.Place_of_birth,
      TaxIDcode: data.TaxIDcode,
      Mobilephone: data.Mobilephone,
      Maritalstatus: data.Maritalstatus,
      Qualification: data.Qualification,
      Profession: data.Profession,
      ASL_assistance: data.ASL_assistance,
      General_Practitioner_Name: data.General_Practitioner_Name,

      Domicile: {
        Address: data.Domicile.Address || "",
        Latitude: data.Domicile.Latitude || "",
        Longitude: data.Domicile.Longitude || "",
      },
      Residence: {
        Address: data.Residence.Address || "",
        Latitude: data.Residence.Latitude || "",
        Longitude: data.Residence.Longitude || "",
      },

      Children: {
        has_children: data.has_children,
        children_gender: data.children_gender,
        type_of_delivery: data.type_of_delivery,
        pregnancy_complications: data.pregnancy_complications,
        are_you_pregnant: data.are_you_pregnant,
        week_of_gestation: data.week_of_gestation,
      },
      Brother_sisters: {
        have_brothers_sisters: data.have_brothers_sisters,
        how_many_brothers_sisters: data.how_many_brothers_sisters,
      },
      Parents_alive: {
        isParentsAlive: data.isParentsAlive,
        who_is_alive: data.who_is_alive,
      },
    };

    dispatch(addPatientProfile(formattedData));
  };

  useEffect(() => {
    if (msg) {
      toast.success(msg);
      reset(); // or keep your values if you prefer
    }
    if (error) {
      reset();
    }
  }, [msg, error, reset]);

  return (
    <form onSubmit={handleSubmit(handlePersonalInfo)}>
        <div className="px-5">
      {/* PERSONAL DETAILS */}
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
        Personal Details
      </Typography>
      <Grid container columns={12} spacing={2}>
        {/* Name & Surname */}
        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">Name and surname</label>
          <input
            type="text"
            {...register("Name_Surname", {
              required: "Name is required",
            })}
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            disabled
          />
          {errors.Name_Surname && (
            <p className="text-red-500 text-xs mt-1">
              {errors.Name_Surname.message}
            </p>
          )}
        </Grid>

        {/* DOB */}
        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">Date of Birth</label>
          <input
            type="date"
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("DOB", {
              required: "Date of birth is required",
            })}
            disabled={disabledFields}
          />
          {errors.DOB && (
            <p className="text-red-500 text-xs mt-1">
              {errors.DOB.message}
            </p>
          )}
        </Grid>

        {/* Place of Birth */}
        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">Place Of Birth</label>
          <input
            type="text"
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("Place_of_birth", {
              required: "Enter your place of birth",
            })}
            disabled={disabledFields}
          />
          {errors.Place_of_birth && (
            <p className="text-red-500 text-xs mt-1">
              {errors.Place_of_birth.message}
            </p>
          )}
        </Grid>

        {/* Tax ID Code */}
        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">Tax ID code</label>
          <input
            type="text"
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("TaxIDcode", {
              maxLength: {
                value: 25,
                message: "Tax ID code must be at most 25 characters long",
              },
              pattern: {
                value: /^[a-zA-Z0-9]*$/,
                message: "Tax ID code must be alphanumeric",
              },
            })}
            onInput={(e) =>
              (e.target.value = e.target.value.toUpperCase())
            }
            disabled={disabledFields}
          />
          {errors.TaxIDcode && (
            <p className="text-red-500 text-xs mt-1">
              {errors.TaxIDcode.message}
            </p>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">Mobile phone</label>
          <input
            type="text"
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("Mobilephone", {
              required: "Mobile number is required",
            })}
            disabled={disabledFields}
          />
          {errors.Mobilephone && (
            <p className="text-red-500 text-xs mt-1">
              {errors.Mobilephone.message}
            </p>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">Mail</label>
          <input
            type="email"
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("mail", {
              required: "Email is required",
            })}
            disabled
          />
          {errors.mail && (
            <p className="text-red-500 text-xs mt-1">
              {errors.mail.message}
            </p>
          )}
        </Grid>

      </Grid>


      <Divider sx={{ my: 2 }} />

      {/* DOMICILE / RESIDENCE */}
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
        Address Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <AddressAutocomplete
            label="Domicile Address"
            name="Domicile"
            errors={errors.Domicile}
            setValue={setValue}
            disabled={disabledFields}
            readOnly={false} // user can type or delete
            // Show what the form currently has for Domicile
            initialAddress={watch("Domicile.Address")}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                {...register("sameAsDomicile")}
                disabled={disabledFields}
              />
            }
            label="Same as Domicile?"
          />

          <AddressAutocomplete
            label="Residence Address"
            name="Residence"
            errors={errors.Residence}
            setValue={setValue}
            disabled={disabledFields || sameAsDomicile}
            // readOnly if "Same as Domicile?" is checked
            readOnly={sameAsDomicile}
            // Show what the form currently has for Residence
            initialAddress={watch("Residence.Address")}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

  
      {/* FAMILY DETAILS */}
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
        Family Details
      </Typography>
      <Grid container spacing={2}>
      

        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">Marital status</label>
          <select
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("Maritalstatus")}
            disabled={disabledFields}
          >
            <option value="">Select</option>
            <option value="Single">Single</option>
            <option value="Married">Married</option>
          </select>
        </Grid>
        {/* Children */}
        <Grid item xs={12} md={6} lg={4}>
          <label className="text-sm mb-2 block">Children</label>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <AccordionHeader
                fieldName="has_children"
                register={register}
                disabledFields={disabledFields}
              />
            </AccordionSummary>
            {has_children === "yes" && (
              <AccordionDetails>
                <ChildrenSection
                  register={register}
                  disabledFields={disabledFields}
                  children_gender={children_gender}
                  are_you_pregnant={watch("are_you_pregnant")}
                />
              </AccordionDetails>
            )}
          </Accordion>
        </Grid>

      </Grid>
      <Grid container spacing={2}>
        
        {/* Brothers / Sisters */}
        <Grid item xs={12} md={6} lg={4}>
          <label className="text-sm mb-2 block">Brothers or sisters</label>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <AccordionHeader
                fieldName="have_brothers_sisters"
                register={register}
                disabledFields={disabledFields}
              />
            </AccordionSummary>
            {have_brothers_sisters === "yes" && (
              <AccordionDetails>
                <label className="text-sm mt-2 mb-1 block">
                  How many brothers or sisters
                </label>
                <input
                  type="text"
                  {...register("how_many_brothers_sisters")}
                  className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                  disabled={disabledFields}
                />
              </AccordionDetails>
            )}
          </Accordion>
        </Grid>

        {/* Parents */}
        <Grid item xs={12} md={6} lg={4}>
          <label className="text-sm mb-2 block">Parents alive</label>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <AccordionHeader
                fieldName="isParentsAlive"
                register={register}
                disabledFields={disabledFields}
              />
            </AccordionSummary>
            {isParentsAlive === "yes" && (
              <AccordionDetails>
                <ParentsSection
                  register={register}
                  disabledFields={disabledFields}
                />
              </AccordionDetails>
            )}
          </Accordion>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* PROFESSIONAL DETAILS */}
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
        Professional Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">Qualification</label>
          <input
            type="text"
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("Qualification", {
              required: "Qualification is required",
            })}
            disabled={disabledFields}
          />
          {errors.Qualification && (
            <p className="text-red-500 text-xs mt-1">
              {errors.Qualification.message}
            </p>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">Profession</label>
          <input
            type="text"
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("Profession", {
              required: "Profession is required",
            })}
            disabled={disabledFields}
          />
          {errors.Profession && (
            <p className="text-red-500 text-xs mt-1">
              {errors.Profession.message}
            </p>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">ASL assistance</label>
          <input
            type="text"
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("ASL_assistance")}
            disabled={disabledFields}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <label className="text-sm mb-2 block">
            Name General Practitioner
          </label>
          <input
            type="text"
            className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
            {...register("General_Practitioner_Name")}
            disabled={disabledFields}
          />
        </Grid>
      </Grid>

      {/* ACTION BUTTONS */}
      {!patientData?.isUpdated && (
        <div className="flex gap-3 mt-4 justify-end border-t-2 pt-4 border-slate-300">
          <button
            type="button"
            className="rounded-full py-3 px-12 bg-red-200 text-sm text-red-700 hover:bg-red-500 hover:text-white transition-all delay-300"
            onClick={() => reset()}
          >
            Reset
          </button>
          <button
            type="submit"
            className="rounded-full py-3 px-12 bg-primary text-sm text-white hover:bg-black transition-all delay-300"
          >
            Save
          </button>
        </div>
      )}

      {isEditable === true && !disabledFields && (
        <div className="flex gap-3 mt-4 justify-end border-t-2 pt-4 border-slate-300">
          <button
            type="button"
            onClick={() => {
              setIsPersonalEditing(false);
              setPersonalFieldDisable(true);
            }}
            className="rounded-full py-3 px-12 bg-red-200 text-sm text-red-700 hover:bg-red-500 hover:text-white transition-all delay-300"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-full py-3 px-12 bg-primary text-sm text-white hover:bg-black transition-all delay-300"
          >
            Update
          </button>
        </div>
      )}
      </div>
    </form>
  );
};

export default PersonalForm;
